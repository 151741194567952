import {useLocale} from '@common/hooks/useLocale'
import {useOutsideClick} from '@common/hooks/useOutsideClick'
import {useRouter} from 'next/router'
import {LegacyRef, useState} from 'react'

type ItemList = {
  label: string
  href: string
}
interface IDropdown {
  itemList: ItemList[]
  isMobileNavActive: boolean
  noEng: boolean
}

const Dropdown = ({itemList, isMobileNavActive, noEng}: IDropdown) => {
  const locale = useLocale()
  const [open, setOpen] = useState(false)
  const ref = useOutsideClick(() => setOpen(false)) as LegacyRef<HTMLDivElement> | undefined

  const router = useRouter()

  const changeLanguage = () => {
    router.push(router.asPath, router.asPath, {locale: locale === 'ru' ? 'en' : 'ru'})
  }

  return (
    <div className="dropdown" ref={ref}>
      <button
        className={`header__dropdown-button ${open ? 'is-active' : ''}`}
        type="button"
        aria-label="Дополнительные пункты навигации"
        onClick={() => setOpen(prevState => !prevState)}
      >
        <span className={`sandwich__menu--simple ${open ? 'is-active' : ''}`} />
      </button>
      {open ? (
        <ul className="menu">
          {!noEng && (
            <li className="menu-item">
              <a className={`${!isMobileNavActive ? 'dynamic-adaptive' : ''}`} onClick={changeLanguage}>
                {locale === 'ru' ? 'Eng' : 'Ru'}
              </a>
            </li>
          )}
          {itemList.map(({label, href}) => (
            <li key={label} className="menu-item">
              <a href={href}>{label}</a>
            </li>
          ))}
        </ul>
      ) : null}
    </div>
  )
}

export default Dropdown
