import {ECustomActions, EPage} from '@common/types'
import {ESocialKeys} from '@redux/types'

export interface INavigationElement {
  page: EPage
  href: string | null
  text: string
  textEn: string | null
  radius: string | null
  data: string | null
  onlyMobile: boolean
  customActions: ECustomActions | null
}

export interface INavigation {
  left: INavigationElement[]
  right: INavigationElement[]
}

export const navigation: INavigation = {
  left: [
    {
      page: EPage.SERVICES,
      href: '/services',
      text: 'Услуги',
      textEn: 'Services',
      radius: null,
      data: null,
      onlyMobile: false,
      customActions: null
    },
    {
      page: EPage.EXPERTISE_PAGE,
      href: '/expertise',
      text: 'Экспертиза',
      textEn: null,
      radius: 'link--radius-small',
      data: null,
      onlyMobile: false,
      customActions: null
    },
    {
      page: EPage.PROJECTS,
      href: '/projects',
      text: 'Проекты',
      textEn: 'Projects',
      radius: 'link--radius-left',
      data: null,
      onlyMobile: false,
      customActions: null
    },
    {
      page: EPage.CONTACTS,
      href: '/contacts',
      text: 'Контакты',
      textEn: 'Contacts',
      radius: null,
      data: null,
      onlyMobile: false,
      customActions: null
    },
    {
      page: EPage.BLOG,
      href: '/blog',
      text: 'Блог',
      textEn: null,
      radius: 'link--radius-small',
      data: null,
      onlyMobile: false,
      customActions: null
    },
    {
      page: EPage.VACANCIES,
      href: '/vacancies',
      text: 'Вакансии',
      textEn: null,
      radius: null,
      data: null,
      onlyMobile: true,
      customActions: null
    },
    {
      page: EPage.PARTNERS_PAGE,
      href: '/partners',
      text: 'Партнерская программа',
      textEn: null,
      radius: null,
      data: null,
      onlyMobile: true,
      customActions: null
    }
  ],
  right: [
    {
      page: EPage.CUSTOM_PRESENTATION,
      href: null,
      text: 'Презентация',
      textEn: null,
      radius: 'link--radius-right ',
      data: null,
      onlyMobile: false,
      customActions: ECustomActions.PRESENTATIONS
    },
    {
      page: EPage.CUSTOM_HEADER,
      href: null,
      text: 'Обсудить проект',
      textEn: null,
      radius: 'link--radius-right link--violet',
      data: 'desktop-first',
      onlyMobile: false,
      customActions: ECustomActions.PROJECT
    }
  ]
}

export const navigationDropdown = [
  {
    page: EPage.VACANCIES,
    href: '/vacancies',
    label: 'Вакансии'
  },
  {
    page: EPage.PARTNERS_PAGE,
    href: '/partners',
    label: 'Партнерская программа'
  },
]

export const socials = [
  {
    key: ESocialKeys.vc,
    href: 'https://vc.ru/mobileup',
    name: 'icon-vc',
    ariaLabel: 'Мы в Виси',
    class: 'social__link--vc'
  },
  {
    key: ESocialKeys.vk,
    href: 'https://vk.com/mobileup_studio',
    name: 'icon-vk',
    ariaLabel: 'Мы в контакте',
    class: 'social__link--vk'
  },
  {
    key: ESocialKeys.youtube,
    href: 'https://www.youtube.com/channel/UCo4Y1mYscxGxoyFgu0TOd_A',
    name: 'icon-youtube',
    ariaLabel: 'Мы в ютубе',
    class: 'social__link--youtube'
  },
  {
    key: ESocialKeys.telegram,
    href: 'https://t.me/upmobileup',
    name: 'icon-telegram',
    ariaLabel: 'Мы в телеграмме',
    class: 'social__link--telegram'
  },
  {
    key: ESocialKeys.medium,
    href: 'https://medium.com/mobileup',
    name: 'icon-medium',
    ariaLabel: 'Мы в Медиум',
    class: 'social__link--medium'
  },
  {
    key: ESocialKeys.behance,
    href: 'https://www.behance.net/mobileup',
    name: 'icon-behance',
    ariaLabel: 'Мы в Беханс',
    class: 'social__link--behance'
  }
]
