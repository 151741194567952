import {useLocale} from '@common/hooks/useLocale'
import {INavigationElement} from '@common/layout/layout.constants'
import Link from 'next/link'
import {useRouter} from 'next/router'

interface IHeaderLinkProps {
  nav: INavigationElement
  index: number
  isMobileNavActive: boolean
}

export const HeaderLink = ({nav, index, isMobileNavActive}: IHeaderLinkProps) => {
  const router = useRouter()
  const locale = useLocale()
  const isActive = nav.href === router.pathname

  return (
    <li
      key={nav.page ?? 'custom'}
      className="main-nav__item"
      data-nav-item={nav.data}
      style={{transitionDelay: `${isMobileNavActive ? 0.2 * (index + 1) + 0.1 : 0}s`}}
    >
      {isActive ? (
        <span className={`main-nav__link link ${nav.radius ?? ''} is-active`}>{locale === 'en' ? nav.textEn : nav.text}</span>
      ) : (
        <Link className={`main-nav__link link ${nav.radius ?? ''}`} href={nav.href ?? '#'}>
          {locale === 'en' ? nav.textEn : nav.text}
        </Link>
      )}
    </li>
  )
}
