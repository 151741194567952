import {useLocale} from '@common/hooks/useLocale'
import {INavigationElement, navigation} from '@common/layout/layout.constants'
import { HeaderLink } from '../HeaderLink'
import { HeaderAction } from '../HeaderAction'
import Icon from '@common/components/common/Icon'
import { ITranslation } from '@common/types'
import { useTranslation } from '@common/hooks/useTranslation'

interface IHeaderActionsProps {
  isMobileNavActive: boolean
}

export const HeaderNavigation = ({isMobileNavActive}: IHeaderActionsProps) => {
  const locale = useLocale()
  const t = useTranslation('main') as ITranslation['main']

  const filterNavigation = (navigationList: INavigationElement[]) =>
    navigationList
      .filter(n => (locale === 'en' ? n.textEn : n.text))
      // Фильтруем поля нужные только в мобильном меню
      .filter(n => !n.onlyMobile || isMobileNavActive)

  return (
    <nav className={`main-nav ${isMobileNavActive ? 'is-active' : ''}`}>
      <ul className="main-nav__list">
        {filterNavigation(navigation.left).map((nav, index) => (
          <HeaderLink key={nav.page} nav={nav} index={index} isMobileNavActive={isMobileNavActive} />
        ))}
        {isMobileNavActive &&
          filterNavigation(navigation.right).map((nav, index) => (
            <HeaderAction key={nav.page} nav={nav} index={index} isMobileNavActive={isMobileNavActive} />
          ))}
      </ul>
      {!isMobileNavActive && !!filterNavigation(navigation.right).length &&(
        <ul className="main-nav__actions">
          {filterNavigation(navigation.right).map((nav, index) => (
            <HeaderAction key={nav.page} nav={nav} index={index} isMobileNavActive={isMobileNavActive} />
          ))}
        </ul>
      )}

      <div className="main-nav__buttons">
        <button className="main-nav__buttons-request" type="button" data-form-open="">
          <span className="main-nav__buttons-text">{t.requestTitle}</span>
        </button>
        <a
          className="main-nav__buttons-link"
          href="https://t.me/chat_with_mobileup"
          target="_blank"
          rel="nofollow noopener noreferrer"
          aria-label="Ссылка на телеграм"
        >
          <Icon width={36} height={36} name="icon-telegram" />
        </a>
      </div>
    </nav>
  )
}
