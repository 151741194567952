import React, {memo, useEffect} from 'react'
import {navigationDropdown} from '@common/layout/layout.constants'
import Link from 'next/link'
import {useTypedSelector} from '@common/hooks/useTypedSelector'
import {EPage} from '@common/types'
import {settings} from '@common/settings'
import {useLocale} from '@common/hooks/useLocale'
import {useStateRef} from '@common/hooks/useStateRef'
import {debounce} from '@common/utils/debounce'
import Dropdown from '@components/common/Dropdown'
import {useRouter} from 'next/router'
import { HeaderNavigation } from './HeaderNavigation'

const Header = () => {
  const {page = '', draftMode, noEng = false, settings: appSettings} = useTypedSelector(state => state.app)
  const router = useRouter()
  const [isMobileNavActive, setIsMobileNavActive, isMobileNavRef] = useStateRef<boolean>(false)
  const locale = useLocale()

  const localeTranslation = locale === 'ru' ? 'en' : 'ru'

  const onResize = () => {
    if (window.innerWidth >= 1024 && isMobileNavRef.current) {
      setIsMobileNavActive(false)
    }
  }

  useEffect(() => {
    const onResizeDebounced = debounce(onResize, 100)
    window.addEventListener('resize', onResizeDebounced)
    return () => window.removeEventListener('resize', onResizeDebounced)
  }, [])

  const changeLanguage = () => {
    router.push(router.asPath, router.asPath, {locale: localeTranslation})
  }

  return (
    <header className="header" id="header">
      <div className="container">
        <div className="header__content">
          {page === EPage.HOME ? (
            <span className={`header__logo link ${isMobileNavActive ? 'is-menu' : ''}`} data-header-logo="Переход на главную">
              {settings.project.name}
            </span>
          ) : (
            <Link className={`header__logo link ${isMobileNavActive ? 'is-menu' : ''}`} href="/">
              {settings.project.name}
            </Link>
          )}
          <button
            className={`header__sandwich sandwich ${isMobileNavActive ? 'is-active' : ''}`}
            type="button"
            aria-label="Переключатель отображения меню"
            onClick={() => setIsMobileNavActive(prevState => !prevState)}
          >
            <span className="sandwich__menu" />
          </button>
          <HeaderNavigation isMobileNavActive={isMobileNavActive}/>
          {!noEng && isMobileNavActive && (
            <a className={`header__language link ${!isMobileNavActive ? 'dynamic-adaptive' : ''}`} onClick={changeLanguage}>
              {locale === 'ru' ? 'Eng' : 'Ru'}
            </a>
          )}
          <div className="header__dropdown--desktop">
            <Dropdown itemList={navigationDropdown} isMobileNavActive={isMobileNavActive} noEng={noEng} />
          </div>
        </div>
      </div>
      {draftMode && <div className="draft-mode-tile">Предпросмотр</div>}
    </header>
  )
}

export default memo(Header)
